import React from 'react';

function Studies() {
    return (
        <div className="container">
            
            <section className="secondsection">
                <span className="text-grey" id='stu'>What I have done so far ? </span>
                <h1>Studies</h1>
            </section>
            <hr></hr>
            <div className="timeline">
                <div className='vr'></div>
                <div className="vr"></div>
            </div>
            <div className="studysection1">
                <div className="firstchild">
                    <h4>Class 10</h4>
                    <p>Vignan Steel City Public School</p><span><b>79.2%</b></span>

                </div>
                <div className="secondchild">
                    <h4>Class 12</h4>
                    <p>Little Flower's High School</p><span><b>78.2%</b></span>

                </div>
            </div>
            <hr></hr>
            <div className="timeline">
                <div className='vr'></div>
                <div className="vr"></div>
            </div>
            <div className="studysection2">
                <div className="firstchild">
                    <h4>B.Tech IT</h4>
                    <p>MCKV Institute of Engineering </p><span><b>8.93 CGPA</b></span>

                </div>

                <div className="secondchild">
                    <h4>MERN</h4>
                    <p>Ardent Computech pvt ltd</p>

                </div>
            </div>

        </div>
    );
};

export default Studies;