import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './components/Navbar';
import Intro from './components/Intro';
import Studies from './components/Studies';
// import TestBot from './components/Testbot';
import Endbar from './components/Endbar';
import Projects from './components/Projects';
import About from './components/About';
import Techstack from './components/Techstack';


function App() {
  return (
    <div>
      <Navbar />
      <Intro />
      <Studies />
      <Projects />
      
      <About />
      <Techstack />
      {/* <TestBot /> */}
      <Endbar />
      
      
      
    </div>
  );
}

export default App;
