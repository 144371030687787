import React from 'react';

function About() {
    return (
        <div>
            <div class="offcanvas offcanvas-start" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop" aria-labelledby="staticBackdropLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="staticBackdropLabel">About @nkesh</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        <hr></hr>
                        <hr></hr>
                        <img id="about-image" src='ankesh.jpg' alt='not found'></img>
                        <br></br>
                        <hr></hr>
                        <br></br>
                        <p> I Am ANKESH DUTTA , currently pursuing BTech From MCKVIE

                            In the vibrant world of web development, I am a passionate practitioner of the craft. My journey commenced with a fascination for Frontend development, a canvas to create captivating user experiences. As I delved deeper, the allure of Backend beckoned, leading me to master the MERN stack for its versatility and Flask for its elegance. With MERN, I harness the power of JavaScript to build robust, dynamic applications. Flask, on the other hand, offers a seamless path to crafting scalable backends in Python. This fusion of technologies empowers me to deliver holistic, cutting-edge solutions. Each line of code I write is imbued with creativity and precision, a testament to my unwavering commitment to the art of web development. With every project, I strive to push boundaries, creating digital experiences that leave a lasting impact. As I continue to evolve and innovate, I invite you to join me in shaping the future of web development. Together, let's build extraordinary experiences that resonate and inspire.</p>
                        <h6>Em@il- duttaankesh@gmail.com</h6>

                    </div>
                </div>
            </div>
        </div>

    );
};
export default About;