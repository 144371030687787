import React from 'react';

function Projects() {
    return (
        <div className="container" id='pro'>
            <br></br>
            <br></br>

            <section className="secondsection">
                <span className="text-grey"> What I enjoy doing !! </span>
                <h1>Projects</h1>
            </section>
            <hr></hr>
            <div className="timeline">
                <div className='vr'></div>
                <div className="vr"></div>
                <div className="vr"></div>
            </div>
            <div className="container-grid">

                <div className="griditem"><a href='https://ema.netlify.app/' className='btn btn-outline-primary'>E.M.A Medical System</a></div>
                <div className="griditem"><a href='https://barish.netlify.app/' className='btn btn-outline-primary'>Barish The Weather App</a></div>
                <div className="griditem"><a href='https://convomorse.netlify.app/' className='btn btn-outline-primary'>90s Morse Code Converter</a></div>
            </div>
            <hr></hr>
            <div className="timeline">
                <div className='vr'></div>
                <div className="vr"></div>
                <div className="vr"></div>
            </div>
            <div className="container-grid">

                <div className="griditem"><a href='https://cgresult.netlify.app/' className='btn btn-outline-primary'>Creative Geeks Result Portal</a></div>
                <div className="griditem"><a href='https://cgexamportal.netlify.app/' className='btn btn-outline-primary'>Creative Geeks Quiz Portal</a></div>
                <div className="griditem"><a href='/' className='btn btn-outline-primary' >Creative Geeks Online Compiler</a></div>
            </div>
        </div>
    );
};

export default Projects;