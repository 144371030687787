import React from 'react';
function Techstack() {
    return (
        <div className="container">
            <br></br>
            <br></br>
            <section className="secondsection">
                <span className="text-grey"> These are the tech that I use !! </span>
                <h1>Technology Stack</h1>
            </section>
            <hr></hr>
            <div id="Tech-grid" className="container-grid">
                <div id="tech" className="grid_items">HTML &#128517;</div>
                <div id="tech" className="grid_items">CSS &#128511;</div>
                <div id="tech" className="grid_items">Reactjs &#128512;</div>
                <div id="tech" className="grid_items">Bootstrap &#128511;</div>
                <div id="tech" className="grid_items">Python &#128516;</div>
                <div id="tech" className="grid_items">MERN &#128517;</div>
                <div id="tech" className="grid_items">C / C++ &#128511;</div>
                <div id="tech" className="grid_items">Flask &#128511;</div>
                <div id="tech" className="grid_items">Java &#128510;</div>
                <div id="tech" className="grid_items">SQL &#128511;</div>
            </div>
        </div>
    );
};
export default Techstack;