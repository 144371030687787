import React from 'react';

function Endbar() {
    return (
        <div className="end">
            <div className="border border-info" style={{ padding: "10px" }}>
                <h4 >created by Ankesh Dutta</h4>
                <p>using React & Bootstrap </p>
                <a href="https://www.facebook.com/ankesh.dutta/" class="fa fa-facebook"></a>
                <a href="https://www.linkedin.com/in/ankesh-dutta-178723202/" class="fa fa-linkedin"></a>
                <a href="https://www.youtube.com/channel/UCFtUAlPcA-hid7xFFDUCtfw" class="fa fa-youtube"></a>
                <a href="https://www.instagram.com/b.r.a.v.o_02/?hl=en" class="fa fa-instagram"></a>
            </div>
            <a class="whats-app" href="https://wa.me/916291797947?text=Hi ! Ankesh .... I got this number from your portfolio ." >
                <i class="fa fa-whatsapp my-float"></i>
            </a>
        </div>
    );
};

export default Endbar;