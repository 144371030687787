import React, { useEffect } from 'react';
import Typed from 'typed.js';

function Intro() {

    useEffect(() => {
        const typed = new Typed('#element', {
            strings: ['Web Developer', 'Python Developer', 'Full Stack Developer', 'React Developer'],
            typeSpeed: 100,
            loop: true
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, []);

    return (
        <div className="container">
            <main>
                <br></br>
                <br></br>
                <section className="firstsection">
                    <div className="leftsection">
                        Wassup! My Name is <span className="purple">Ankesh</span>
                        <div>I am a <span id="element"></span></div>
                    </div>
                    <div className="rightsection">
                        <img src="bg2.png" alt="..." />
                    </div>
                </section>
                
            </main>
        </div>
    );
}

export default Intro;
