// Navbar.js
import React, { useEffect, useState } from 'react';
// import './App.css'; // Make sure to import your CSS file

function Navbar() {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">@nkesh.</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="#navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#stu">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" href="#pro">Projects</a>
              </li>
              

              <li className="nav-item">
                <a className="nav-link active" href="https://mediafiles.botpress.cloud/ace4a30c-f906-4c4b-b1e0-09083be4fe82/webchat/bot.html">Anne.ai</a>
              </li>
              <li className="nav-item">
                <button className="nav-link active " type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                  about @nkesh
                </button>
              </li>
              <li className="nav-item">
                <button className="btn " type="button" onClick={toggleDarkMode}>
                  {darkMode ? '🌞' : '🌜'}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
